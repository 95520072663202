.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* CSS untuk mode gelap swal */
.dark-popup {
  background-color: #1c2229; /* Warna latar belakang gelap */
  color: #ffffff; /* Warna teks putih */
}

.dark-title {
  color: #ffffff; /* Warna teks judul */
}

.dark-text {
  color: #cccccc; /* Warna teks konten */
}

/* Pengaturan warna tombol */
.swal2-styled.swal2-confirm {
  background-color: #3085d6 !important; /* Warna tombol konfirmasi */
}

.swal2-styled.swal2-cancel {
  background-color: #d33 !important; /* Warna tombol batal */
}

